#introduksjon {
  max-width: 25rem;
  align-self: center;
}

#resultat {
  flex-direction: column;
  align-items: center;
  margin: 3rem 0 5rem;
  display: flex;
}

#passord {
  color: #000;
  overflow-wrap: anywhere;
  background-color: #b1d0b1;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  padding: 1rem 1.75rem;
  font-family: monospace;
  font-size: 1.625rem;
  display: flex;
}

#passord, .knapp, .innstilling select {
  border-radius: .5rem;
  box-shadow: .5rem .5rem #000;
}

#passord:focus, .knapp:focus, .innstilling select:focus {
  outline: none;
  box-shadow: inset 0 0 0 .25rem #fff;
}

#handlinger {
  align-items: center;
  margin-bottom: 2rem;
  display: flex;
}

#innstillinger {
  align-items: center;
  display: flex;
}

#innstillinger > :not(:first-child) {
  margin-left: 1rem;
}

.innstilling {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.innstilling select {
  height: 3rem;
  appearance: none;
  background: #add8e6;
  border: none;
  padding: 0 1rem;
  font-size: 1rem;
}

.innstilling select:hover {
  cursor: pointer;
  background: #deeff5;
}

.innstilling label {
  margin-bottom: 1rem;
}

.knapp {
  height: 3rem;
  text-align: left;
  background: wheat;
  border: none;
  align-items: center;
  padding: 1rem;
  display: flex;
}

.knapp:hover {
  cursor: pointer;
  background-color: #fbf2e1;
}

.knapp:active {
  transform: translate3d(.125rem, .125rem, 0);
  box-shadow: .375rem .375rem #000;
}

.knapp span {
  font-size: 1rem;
}

.knapp:not(:first-child), .knapp > :not(:first-child) {
  margin-left: 1rem;
}

@media (min-width: 50rem) {
  #passord {
    font-size: 2rem;
  }
}

/*# sourceMappingURL=index.f854b85d.css.map */
