#introduksjon {
    max-width: 25rem;
    align-self: center;
}

#resultat {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 3rem 0 5rem;
}

#passord {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: black;
    background-color: #b1d0b1;
    font-family: monospace;
    font-size: 1.625rem;
    padding: 1rem 1.75rem;
    margin-bottom: 2rem;
    overflow-wrap: anywhere;
}

#passord,
.knapp,
.innstilling select {
    box-shadow: 0.5rem 0.5rem 0 black;
    border-radius: 0.5rem;
}

#passord:focus,
.knapp:focus,
.innstilling select:focus {
    outline: none;
    box-shadow: inset 0 0 0 0.25rem white;
}

#handlinger {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}

#innstillinger {
    display: flex;
    align-items: center;
}

#innstillinger > *:not(:first-child) {
    margin-left: 1rem;
}

.innstilling {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.innstilling select {
    background: lightblue;
    font-size: 1rem;
    height: 3rem;
    border: none;
    padding: 0 1rem;
    appearance: none;
}

.innstilling select:hover {
    cursor: pointer;
    background: #deeff5;
}

.innstilling label {
    margin-bottom: 1rem;
}

.knapp {
    background: wheat;
    border: none;
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 3rem;
    text-align: left;
}

.knapp:hover {
    cursor: pointer;
    background-color: #fbf2e1;
}

.knapp:active {
    box-shadow: 0.375rem 0.375rem 0 black;
    transform: translate3d(0.125rem, 0.125rem, 0);
}

.knapp span {
    font-size: 1rem;
}

.knapp:not(:first-child),
.knapp > *:not(:first-child) {
    margin-left: 1rem;
}

@media (min-width: 50rem) {
    #passord {
        font-size: 2rem;
    }
}
